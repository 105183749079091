<template>
    <div class="google-ad-top-banner" v-if="shouldShowAds">

        <template v-if="isProd && isGoogleAdsEnabled">
            <HideAdsBtn class="hide-ads" />
            <p class="advertisement-info"> {{ translations['shared_Ads_Label'] }}</p>
            <!-- Rankedin Banner Ad Unit -->
            <ins class="adsbygoogle"
                 style="display:inline-block;width:980px;height:120px"
                 data-ad-client="ca-pub-2431348456451065"
                 data-ad-slot="8583451076"></ins>

        </template>
        <template v-if="isPubliftEnabled">
            <HideAdsBtn class="hide-ads" />
            <p class="advertisement-info">{{ translations['shared_Ads_Label'] }}</p>
            <!-- GAM 71161633/RNKDN_rankedin/header -->
            <div id="ad-top-banner" style="min-height: 50px; max-height: 100px; " data-fuse='23201782313'></div>
        </template>
        <!--<img style="height: 120px; width:980px; display: block; margin: auto" src="https://cdn.rankedin.com/images/static/test-banner_320x50.png" />-->

    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import HideAdsBtn from '@/components/rin_new_design/components/GoogleAds/HideAdsButton.vue';


    export default {
        name: 'DesktopBanner',
        components: {
            HideAdsBtn
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
            ...mapGetters('monetization', ['shouldShowAds']),

            isProd() {
                return this.nomenclatures.Environment == 'prod';
            },
            isPubliftEnabled() {
                return this.nomenclatures.config.isPubliftEnabled;
            },
            isGoogleAdsEnabled() {
                return this.nomenclatures.config.isGoogleAdsEnabled;
            }
        },
        mounted() {
            if (this.isProd && this.isGoogleAdsEnabled) {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            }

            if (this.isPubliftEnabled) {
                let fusetag = window.fusetag || (window.fusetag = { que: [] });
                fusetag.que.push(function () {
                    fusetag.registerZone('ad-top-banner');
                });
            }
        }
    }
</script>

<style scoped>
    #ad-top-banner {
        min-height: 50px;
        max-height: 100px;
        overflow: hidden;
    }
</style>