<template>
    <div v-if="$windowSize.isMobile && shouldShowAds">
        <template v-if="isProd && isGoogleAdsEnabled">
            <HideAdsBtn class="hide-ads" />
            <p class="advertisement-info">{{ translations['shared_Ads_Label'] }}</p>
            <ins class="adsbygoogle"
                 style="display:block"
                 data-ad-client="ca-pub-2431348456451065"
                 data-ad-slot="2827628646"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
        </template>
        <template v-if="isPubliftEnabled">
            <HideAdsBtn class="hide-ads" />
            <p class="advertisement-info">{{ translations['shared_Ads_Label'] }}</p>
            <!-- GAM 71161633/RNKDN_rankedin/incontent_2 -->
            <div id="ad-mobile-incontent2" style="min-height: 50px; max-height: 300px; " data-fuse="23201782316"></div>

            <!--<img style="width:100%; display: block; margin: auto" src="https://cdn.rankedin.com/images/static/dummy200x200.png" />-->
        </template>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import HideAdsBtn from '@/components/rin_new_design/components/GoogleAds/HideAdsButton.vue';

    export default {
        name: 'Box1',
        components: {
            HideAdsBtn
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
            ...mapGetters('monetization', ['shouldShowAds']),

            isProd() {
                return this.nomenclatures.Environment == 'prod';
            },
            isPubliftEnabled() {
                return this.nomenclatures.config.isPubliftEnabled;
            },
            isGoogleAdsEnabled() {
                return this.nomenclatures.config.isGoogleAdsEnabled;
            }
        },
        mounted() {
            if (this.isProd && this.$windowSize.isMobile && this.shouldShowAd && this.isGoogleAdsEnabled) {
                setTimeout(() => {
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                }, 500);
            }

            if (this.$windowSize.isMobile && this.shouldShowAd && this.isPubliftEnabled) {
                let fusetag = window.fusetag || (window.fusetag = { que: [] });
                fusetag.que.push(function () {
                    fusetag.registerZone('ad-mobile-incontent2');
                });
            }
        }
    }
</script>
<style>
    .advertisement-info {
        margin-bottom: 2px;
        font-size: 12px;
        color: grey;
        text-align: right;
        margin-right: 10px;
    }

    .hide-ads {
        position: absolute;
    }
</style>

<style scoped>
    #ad-mobile-incontent2 {
        min-height: 50px;
        max-height: 300px;
        overflow: hidden;
    }
</style>