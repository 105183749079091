<template>
    <div class="google-ad-bottom-banner"  v-if="shouldShowAds">

        <template v-if="isProd && isGoogleAdsEnabled">

            <!-- Rankedin Banner Ad Unit -->
            <HideAdsBtn class="hide-ads" />
            <p class="advertisement-info">{{ translations['shared_Ads_Label'] }}</p>
            <ins class="adsbygoogle"
                 style="display:inline-block;width:100%;height:50px"
                 data-ad-client="ca-pub-2431348456451065"
                 data-ad-slot="1706358634"
                 data-full-width-responsive="true"></ins>

        </template>

        <template v-if="isPubliftEnabled">
            <HideAdsBtn class="hide-ads" />
            <p class="advertisement-info">{{ translations['shared_Ads_Label'] }}</p>
            <!-- GAM 71161633/RNKDN_rankedin/footer -->
            <div style="min-height: 50px; max-height: 50px;" id="ad-mobile-bottom-banner" data-fuse="23203968655"></div>
        </template>
        <!--<img style="height: 50px; width:350px; display: block; margin: auto" src="https://cdn.rankedin.com/images/static/test-banner_320x50.png" />-->

    </div>
</template>
<style>
    .google-ad-bottom-banner {
        width: 100%;
        background-color: white;
    }
    .advertisement-info {
        margin-bottom: 2px;
        font-size: 12px;
        color: grey;
        text-align: right;
        margin-right: 10px;
    }

    .hide-ads {
        position: absolute;
    }
</style>
<script>
    import { mapGetters } from 'vuex';
    import HideAdsBtn from '@/components/rin_new_design/components/GoogleAds/HideAdsButton.vue';

    export default {
        name: 'BottomBanner',
        components: {
            HideAdsBtn
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
            ...mapGetters('monetization', ['shouldShowAds']),

            isProd() {
                return this.nomenclatures.Environment == 'prod';
            },

            isPubliftEnabled() {
                return this.nomenclatures.config.isPubliftEnabled;
            },
            isGoogleAdsEnabled() {
                return this.nomenclatures.config.isGoogleAdsEnabled;
            }
        },
        mounted() {
            if (this.isProd && this.isGoogleAdsEnabled) {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            }

            if (this.isPubliftEnabled) {
                let fusetag = window.fusetag || (window.fusetag = { que: [] });
                fusetag.que.push(function () {
                    fusetag.registerZone('ad-mobile-bottom-banner');
                });
            }
        }
    }
</script>

<style scoped>
    #ad-mobile-bottom-banner {
        min-height: 50px;
        max-height: 50px;
        overflow: hidden;
    }
</style>