import Vue from 'vue';
import Axios from 'axios';
Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.withCredentials = true;

import router from '@/router/router';

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import VueMeta from 'vue-meta';
Vue.use(VueMeta);
Vue.config.devtools = true;

import { store } from '@/store/store';

import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css';
Vue.use(VueCroppie);

import WindowSize from '@/plugins/WindowSize';
Vue.use(WindowSize);

import spaEventBus from '@/app/eventBus';

import ModalAds from "@/plugins/ModalAds";
Vue.use(ModalAds, { router: router, store: store, http: Axios, eventBus: spaEventBus });

import GoogleAds from "@/plugins/GoogleAds";
Vue.use(GoogleAds, router, store, spaEventBus, Axios);

import PubliftAds from "@/plugins/PubliftAds";
Vue.use(PubliftAds, router, store, spaEventBus, Axios);

import Logger from '@/plugins/Logger';
Vue.use(Logger, { store });

import AuthClient from '@/plugins/AuthClient';
Vue.use(AuthClient, { router, store, Logger });

import RouteQuery from '@/plugins/RouteQuery';
Vue.use(RouteQuery, router);

import LoadScript from '@/plugins/LoadScript';
Vue.use(LoadScript);

import DataTableReinjectors from '@/plugins/DataTableReinjectors';
Vue.use(DataTableReinjectors);

import InputCustomFocus from '@/plugins/InputCustomFocus';
Vue.use(InputCustomFocus, { store });


import PortalVue from 'portal-vue';
Vue.use(PortalVue);

import SignalRClient from '@/plugins/SignalRClient';
Vue.use(SignalRClient, { store });

import Cookies from 'js-cookie';
Vue.prototype.$cookies = Cookies;

import VueGtag from "vue-gtag";

import _ from 'lodash';

import { GET_LAYOUT_MODEL_ASYNC, GET_FEATURE_META_ASYNC } from "@/store/modules/layout-module.js";

let getNomenclatures = store.dispatch('getNomenclatures', `${window.apiDomain}/nomenclature/GetLayoutNomenclatures`);
let getlayoutinfo = getNomenclatures.then(() => {
    return store.dispatch(GET_LAYOUT_MODEL_ASYNC);
});
getlayoutinfo.then(() => SignalRClient.connect());

getNomenclatures.then(() => Logger.init());
getNomenclatures.then(() => {
    if (store.state.nomenclatures.nomenclatures.GoogleAnalyticsMeasurementId)
        Vue.use(VueGtag, {
            config: { id: store.state.nomenclatures.nomenclatures.GoogleAnalyticsMeasurementId }
        }, router);
});

router.beforeEach(async (to, from, next) => {
    const translationsPromise = getNomenclatures.then(() => {
        return store.dispatch('asyncUpdateTranslationsVersion', { cdn: store.state.nomenclatures.nomenclatures.BlobDomain, language: to.params.language || 'en' }).catch(e => {
            Logger.error(e);
            store.dispatch("getTranslations", to.params.language || 'en');            
        });        
    });
    await Promise.all([translationsPromise, getNomenclatures, getlayoutinfo]);

    //Cookie check after each route change - should be temporary solution as it is constatly calling server
    AuthClient.checkServerAuthCookieStatus();

    AuthClient.checkAuthRequirements(to, next);
});

router.beforeEach((to, from, next) => {

    if (to.meta?.breadcrumbRecord || to.meta.ignoreBreadcrumbMissmatch) {
        store.commit('breadcrumbs/setBreadcrumb', {
            bc: to.matched.filter(x => x.meta.breadcrumbRecord).map(x => {
                // dont spread the object otherwise it loses reactivity
                const res = x.meta.breadcrumbRecord;
                res.name = x.name ?? x.meta.name;
                res.params = to.params;
                res.query = to.query;
                return res;
            })
        });
    } else {
        store?.commit('breadcrumbs/resetAllBreadcrumbs');
    }

    next();
});

let previousMetaCall = {
    feature: '',
    getByRelatedEntity: '',
    id: 0,
    rankedinId: '',
    language: ''
};

function compareMetaCalls(prev, curr) {
    return prev.feature === curr.feature &&
        prev.getByRelatedEntity === curr.getByRelatedEntity &&
        prev.id === curr.id &&
        prev.rankedinId === curr.rankedinId &&
        prev.language === curr.language;
}

_.throttle(() => { GoogleAds.tick() }, 30000, {
    leading: true
});

router.afterEach((to) => {
    store.dispatch('updateLayoutPath', {
        path: to.path,
        language: to.params.language,
        nomenclatures: store.state.nomenclatures.nomenclatures,
    });
    store.dispatch("updateIsEmbed", to);    
    store.commit('ui/resetBodyModalsStack');
    store.dispatch('ui/setMonetizationModalState', false);
    store.commit('monetization/updateShowFreeTrialModal', false);
    store.dispatch('ui/setPremiumModalState', false);
	store.commit('ui/incrementNavigationsCount');

	to?.meta?.hasAliternativeBackground ? store.commit('ui/setAlternativeBackgroud', true) : store.commit('ui/setAlternativeBackgroud', false);

    if (to.meta.feature) {

        let currentMetaCall = {
            feature: to.meta.feature,
            getByRelatedEntity: to.meta.getByRelatedEntity,
            id: Number(to.params.id) || Number(to.query.id)
                || Number(to.params.tournamentId) || Number(to.query.tournamentId) || Number(to.query.teamleagueid)
                || Number(to.query.poolId)
                || 0,
            rankedinId: to.params.id,
            language: to.params.language
        };

        let isSameMetaCall = compareMetaCalls(previousMetaCall, currentMetaCall);

        if (!isSameMetaCall) {
            previousMetaCall = currentMetaCall;
            store.dispatch(GET_FEATURE_META_ASYNC, currentMetaCall).then(() => {
                ModalAds.generateAd();
                if (store.getters.nomenclatures.config.isGoogleAdsEnabled) GoogleAds.generateAd();
                if (store.getters.nomenclatures.config.isPubliftEnabled) PubliftAds.refreshAds();

                store.commit('breadcrumbs/setFeatureBreadcrumb', { feature: to.meta.feature, value: store.state.layout.page.name, id: store.state.layout.page.id });
                if (to.meta.feature !== 'Tournament' && to.matched.find(x => x.meta.searchHistory === true)) {
                    store.dispatch('searchHistory/setHistory', {
                        name: store.state.layout.page.name,
                        route: {
                            params: to.params,
                            name: to.matched.find((x) => x.meta.searchHistory === true).meta.name
                        }
                    });
                }

                if (to.meta.feature) {
                    store?.commit('monetization/updateCurrentEventType', to.meta.feature);
                } else {
                    //just a cleanup
                    store?.commit('monetization/updateCurrentEventType', '');
                }
            });
        }
        else {
            if (store.getters.nomenclatures.config.isGoogleAdsEnabled) GoogleAds.emitRefreshGoogleAd();
            if (store.getters.nomenclatures.config.isPubliftEnabled) PubliftAds.refreshAds();
        }
    }

    //scroll fix
    setTimeout(() => {
        var hashtag = to.hash;
        if (typeof $(hashtag).offset() == 'undefined') return;
        $('html, body').animate(
            {
                scrollTop: $(hashtag).offset().top,
            },
            500
        );
    }, 1);
});

router.onError(error => {
    Logger.error(error);    
    if (/ChunkLoadError:.*failed./i.test(error.message)) {        
        window.location.reload();
    }
    else if (/Loading.*chunk.*failed./i.test(error.message)) {        
        window.location.reload();
    }
});

window.addEventListener('beforeinstallprompt', e => {
	e.preventDefault();
    store.commit('ui/setPwaPromptRef', e);
});

window.addEventListener('appinstalled', () => {
    store.commit('ui/setPwaPromptRef', null);
});

import MainApp from "@/views/UserPart/MainAppLoader";
new Vue({
    el: '#mainPwaApp',
    router,
    store,
    template: '<MainApp/>',
    components: { 'MainApp': MainApp }
})